var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("report")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 offset-md-2 mt-4 pt-2"
  }, [_vm.error == 1 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t("reporting.please-choose")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [['Staff'].includes(_vm.userRole) ? _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.report-yearly'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.annual")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1) : _vm._e(), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.detailed-yearly-report'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.detailed-annual")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.report-monthly'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.monthly")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.detailed-monthly-report'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.detailed-monthly")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.approved-monthly-report'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.verified-monthly")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1), _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.report-daily'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.daily")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1), ['Staff', 'Super Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.report-soa'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.soa")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1) : _vm._e(), ['Staff', 'Super Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.loan-bmmb'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.bmmb-funding")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1) : _vm._e(), ['Staff', 'Super Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "col-md-6 offset-md-3 mt-4 pt-2"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard.wills'
      }
    }
  }, [_c('div', {
    staticClass: "card rounded shadow bg-dark border-0"
  }, [_c('div', {
    staticClass: "pt-4 pb-4 pl-4 pr-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "pt-2"
  }, [_c('h4', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.$t("reporting.will")))])]), _c('div', {
    staticClass: "pt-1"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/next.png",
      "height": "30",
      "alt": ""
    }
  })])])])])])])], 1) : _vm._e()])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }